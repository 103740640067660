import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles, Popover, List, Backdrop } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import appRoutes from 'appRoutes';
import Button from 'components/Button';
import Icon from 'components/Icon';

import styles from './styles';

class FiltersMenu extends PureComponent {
  static propTypes = {
    label: PropTypes.node.isRequired,
    buttonProps: PropTypes.shape(),
    menuProps: PropTypes.shape(),
    children: PropTypes.node,
    classes: PropTypes.shape().isRequired,
    type: PropTypes.string.isRequired,
    onOpen: PropTypes.func,
    className: PropTypes.string,
    popoverClassName: PropTypes.string,
    locked: PropTypes.bool,
    disabled: PropTypes.bool,
    divided: PropTypes.bool,
    icon: PropTypes.node,
    iconName: PropTypes.string,
    iconClass: PropTypes.shape(),
    mobile: PropTypes.bool,
    close: PropTypes.bool,
    applyFilter: PropTypes.func,
    menuHeight: PropTypes.string,
    menuWidth: PropTypes.shape(),
  };

  static defaultProps = {
    buttonProps: {},
    menuProps: {},
    children: null,
    onOpen: () => {},
    className: '',
    popoverClassName: '',
    locked: false,
    disabled: false,
    divided: false,
    icon: null,
    iconName: null,
    iconClass: null,
    mobile: false,
    close: false,
    applyFilter: () => {},
    menuHeight: '',
    menuWidth: {},
  };

  state = {
    anchor: null,
  };

  handleOpen = event => {
    this.setState({ anchor: event.currentTarget });
    this.props.onOpen(this.handleClose);
  };

  handleClose = () => {
    const { close, applyFilter } = this.props;
    if (!close) {
      applyFilter('apply');
    }
    this.setState({ anchor: null });
  };

  componentDidUpdate() {
    if (this.props.close) {
      this.handleClose();
    }
  }

  renderIcon = () => {
    const { iconName, iconClass } = this.props;
    return <Icon icon={iconName} className={iconClass} />;
  };

  renderPaywall = () => {
    const { classes } = this.props;

    return (
      <div className={classes.paywall}>
        <div className={classes.paywallLabel}>Oops! You don’t have this feature yet!</div>
        <Icon icon="unlock" width={50} height={50} className={classes.paywallIcon} />
        <Button
          className={cn(classes.supportButton, 'contact-support')}
          to={appRoutes.contactSupport()}
          id="contact-support"
          target="_blank"
        >
          Unlock My Filters
        </Button>
      </div>
    );
  };

  render() {
    const {
      label,
      buttonProps,
      menuProps,
      children,
      classes,
      type,
      className,
      popoverClassName,
      locked,
      disabled,
      divided,
      icon,
      iconName,
      mobile,
      menuHeight,
      menuWidth,
    } = this.props;
    const { anchor } = this.state;
    const buttonIcon = anchor ? 'chevron-up' : 'chevron-down';
    const classNames = cn(classes.button, {
      [classes.buttonActive]: anchor,
      [classes.buttonTag]: type === 'tag',
      [classes.opened]: anchor && divided,
      [className]: className,
    });
    const paperClassNames = cn(classes.button, {
      [classes.paper]: !mobile,
      [classes.mobilePaper]: mobile,
      [popoverClassName]: popoverClassName,
    });
    const maxHeight = menuHeight || '70%';

    return mobile ? (
      <>
        <Button
          className={classNames}
          iconAfter={mobile ? null : buttonIcon}
          variant={type}
          color="inverse"
          onClick={this.handleOpen}
          disableRipple={locked}
          disabled={disabled}
          aria-label="Filter"
          aria-haspopup="true"
          {...buttonProps}
        >
          <span className={classes.buttonContainer}>
            {locked && <Lock className={classes.lockIcon} />}
            {icon && icon}
            {iconName && this.renderIcon()}
            {label && label}
          </span>
        </Button>
        <Backdrop open={!!anchor} className={classes.backdrop}>
          <Popover
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'bottom',
            }}
            transformOrigin={{
              vertical: 'top',
            }}
            open={!!anchor}
            onClose={this.handleClose}
            getContentAnchorEl={null}
            classes={{ paper: paperClassNames }}
            marginThreshold={0}
            PaperProps={{ style: { maxHeight, ...menuWidth } }}
            data-node="popover-menu"
            {...menuProps}
          >
            <List>{locked ? this.renderPaywall() : children}</List>
          </Popover>
        </Backdrop>
      </>
    ) : (
      <>
        <Button
          className={classNames}
          iconAfter={mobile ? null : buttonIcon}
          variant={type}
          color="transparent"
          onClick={this.handleOpen}
          disableRipple={locked}
          disabled={disabled}
          aria-haspopup="true"
          {...buttonProps}
        >
          <span className={classes.buttonContainer}>
            {locked && <Lock className={classes.lockIcon} />}
            {icon}
            {label}
          </span>
        </Button>
        <Popover
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={!!anchor}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          classes={{ paper: paperClassNames }}
          PaperProps={{ style: { maxHeight, ...menuWidth } }}
          {...menuProps}
          data-node="popover-menu"
        >
          <List>{locked ? this.renderPaywall() : children}</List>
        </Popover>
      </>
    );
  }
}

export default withStyles(styles)(FiltersMenu);
